<template>
  <model-form
    :model-id="enrollment_id"
    model-name="enrollment"
    :baseItem="baseItem"
    :duplicating="duplicating"
    :createMutation="CreateEnrollmentDocument"
    :updateMutation="UpdateEnrollmentDocument"
    :query="EnrollmentFormDocument"
    :preprocessor="preprocessor"
    :preparer="preparer"
    :fields="fields"
    @created="$emit('created', $event.createEnrollment!)"
    @updated="$emit('updated', $event.updateEnrollment!)"
    @cancel="$emit('cancel')"
  >
    <template #title>Nuova {{ $t("enrollment.name") }}</template>
  </model-form>
</template>

<script setup lang="ts">
import ModelForm from "@/bytenest-cli/form/ModelForm.vue";
import { graphql } from "@/gql/gql";
import { Field, BaseModel } from "@/bytenest-cli/definitions";
import { useI18n } from "vue-i18n";
import { required, sameAs } from "@vuelidate/validators";
import { useEnrollmentFields } from "../../composables/useEnrollmentFields";

const EnrollmentFormDocument = graphql(`
  query PolinoteEnrollmentForm($id: ID!) {
    enrollment(id: $id) {
      ...polinoteEnrollment
    }
  }
`);

const CreateEnrollmentDocument = graphql(`
  mutation CreatePolinoteEnrollmentDocument($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      ...polinoteEnrollment
    }
  }
`);

const UpdateEnrollmentDocument = graphql(`
  mutation UpdatePolinoteEnrollmentDocument($id: ID!, $input: UpdateEnrollmentInput!) {
    updateEnrollment(id: $id, input: $input) {
      ...polinoteEnrollment
    }
  }
`);

defineEmits<{
  created: [enrollment: BaseModel];
  updated: [enrollment: BaseModel];
  cancel: [];
}>();

const props = defineProps({
  enrollment_id: { type: String },
  baseItem: {
    type: Object,
    default: () => ({}),
  },
  duplicating: Boolean,
  showNotes: Boolean,
});

const { t } = useI18n();

function preprocessor(model: BaseModel) {
  const { course, ...remaining } = model;
  return remaining;
}

function preparer(model: BaseModel) {
  return { ...model, course: model.courseFrequency.course };
}

graphql(`
  fragment polinoteEnrollment on Enrollment {
    id
    start_timestamp
    payment_method
    payment_recurrency
    status
    lessons_to_do
    notes
    studyClass {
      id
      name
    }
    courseFrequency {
      id
      description
      course {
        id
        name
      }
    }
    teacher {
      id
      name
    }
    student {
      id
      full_name
      location {
        id
        name
      }
    }
    canEditNotes
  }
`);

const { Students, Course, CourseFrequency, StudyClass, Teacher, StartTimestamp, LessonsToDo } =
  useEnrollmentFields(props);

const fields: Field[] = [
  Students,
  Course,
  CourseFrequency,
  StudyClass,
  Teacher,
  StartTimestamp,
  LessonsToDo,
  {
    name: "payment_method",
    type: "autocomplete",
    items: ["bank_transfer", "cash"].map((value) => ({
      title: t(`options.enrollment.payment_method.${value}`),
      value,
    })),
    validation: {
      required,
    },
  },
  {
    name: "payment_recurrency",
    type: "autocomplete",
    items: ["period", "month", "year"].map((value) => ({
      title: t(`options.enrollment.payment_recurrency.${value}`),
      value,
    })),
    validation: {
      required,
    },
  },
  {
    name: "regulation",
    label: t("enrollment.regulation", { link: "https://polinote.it/regolamento-per-gli-allievi/" }),
    type: "checkbox",
    validation: {
      checked: sameAs(true),
    },
    cols: 12,
    isVisible: () => !props.enrollment_id,
  },
  {
    name: "privacy",
    label: t("enrollment.privacy", { link: "https://polinote.it/privacy-policy/" }),
    type: "checkbox",
    validation: {
      checked: sameAs(true),
    },
    cols: 12,
    isVisible: () => !props.enrollment_id,
  },
  {
    name: "notes",
    type: "textarea",
    cols: 12,
    isVisible: ({ canEditNotes }) => canEditNotes,
  },
];
</script>
