import { useDayJS } from "@/bytenest-cli/composable/useDayJS";
export function useTimeslots() {
  const { dayjs } = useDayJS();
  const getTimeSlots = (start: string, end: string, increment = 15) => {
    let startTime = dayjs(start, "HH:mm");
    const endTime = dayjs(end, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    const timeStops: string[] = [];

    while (startTime.isBefore(endTime)) {
      timeStops.push(dayjs(startTime).format("HH:mm"));
      startTime = startTime.add(increment, "minutes");
    }
    return timeStops;
  };

  return { getTimeSlots };
}
