// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

import { VDateInput } from "vuetify/labs/VDateInput";

// @ts-expect-error Vuetify
import colors from "vuetify/lib/util/colors";

// Vuetify
import { createVuetify } from "vuetify";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { useI18n } from "vue-i18n";
import { i18n } from "@/plugins/i18n";

export default createVuetify({
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  defaults: {
    VCard: {
      rounded: "lg",
      class: "overflow-hidden",
    },
    VContainer: {
      class: "px-0 px-sm-4",
    },
  },
  components: {
    VDateInput,
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#1c9d9a",
          secondary: "#1c9d5a",
          tertiary: colors.orange.base,
          card: "#1c9d5a",
          table: "#1c9d5a",
        },
      },
    },
  },
});
