import { ErrorObject } from "@vuelidate/core";
import { GraphQLError } from "graphql";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { BaseModel } from "../definitions";

export declare type ValidationConfig = {
  name: string;
  graphQLErrors: GraphQLError[];
  validation?: any;
  label?: string | ((model: BaseModel) => string);
};

export function useValidation($v: any, model: string): { validate: (config: ValidationConfig) => string[] } {
  const { t: $t } = useI18n();

  function validate(config: ValidationConfig): string[] {
    const { name, validation, label, graphQLErrors = [] } = config;
    const errors = gqlValidationErrors(name, graphQLErrors);

    const isArray: boolean = $v.value[name]?.$each != undefined;

    if (isArray) {
      // if (!$v.value[name].$each.$response.$errors.length) return errors;
    } else if (!validation || !$v.value[name]?.$error) return errors;

    $v.value[name].$errors.forEach((error: ErrorObject) => {
      let labelValue: string | undefined;
      if (typeof label === "function") labelValue = label({ model });
      else labelValue = label;

      const resolved = resolveError(error, labelValue ?? "", name);
      if (resolved != null) errors.push(resolved);
    });

    if (errors.length === 0) errors.push("Unknown error");
    return errors;
  }

  function resolveError(error: ErrorObject, label: string, name: string): string | null {
    //Required
    if (error.$validator === "required")
      return $t("form.validation.field_required", { field: label || $t(`${model}.${name}`) });
    //Email
    if (error.$validator === "email") return $t("form.validation.email", { field: label || $t(`${model}.${name}`) });
    //Field length
    if (error.$validator === "minLength")
      return $t("form.validation.min_length", {
        field: $t("user.password"),
        length: _.get(error.$params, "minLength.min"),
      });
    //Checkbox checked
    if (error.$validator === "checked") return $t("form.validation.checked");
    //Password mismatch
    if (error.$validator === "sameAsPassword")
      return $t("form.validation.password_mismatch", { field: label || $t(`${model}.${name}`) });
    //Min words
    if (error.$validator === "minWords")
      return $t("form.validation.min_words", {
        field: label || $t(`${model}.${name}`),
        length: _.get(error.$params, "minWords.min"),
      });
    //Numeric
    if (error.$validator === "numeric")
      return $t("form.validation.numeric", { field: label || $t(`${model}.${name}`) });
    //Integer
    if (error.$validator === "integer")
      return $t("form.validation.integer", { field: label || $t(`${model}.${name}`) });
    //URL
    if (error.$validator === "url") return $t("form.validation.url", { field: label || $t(`${model}.${name}`) });
    //Min value
    if (error.$validator === "minValue")
      return $t("form.validation.min_value", {
        field: label || $t(`${model}.${name}`),
        value: _.get(error.$params, "minValue.min"),
      });
    //Max value
    if (error.$validator === "maxValue")
      return $t("form.validation.max_value", {
        field: label || $t(`${model}.${name}`),
        value: _.get(error.$params, "maxValue.max"),
      });

    //Later date
    if (error.$validator === "laterThen") {
      return $t("form.validation.later_date", {
        field: label || $t(`${model}.${name}`),
        date: $t(`${model}.${$v.value[name].laterThen.$params.date}`),
      });
    }

    if (error.$validator === "beforeThen") {
      return $t("form.validation.before_date", {
        field: label || $t(`${model}.${name}`),
        date: $t(`${model}.${$v.value[name].beforeThen.$params.date}`),
      });
    }

    return null;
  }

  function getGraphQLError(graphQLError: GraphQLError, name: string) {
    const validationError = _.get(graphQLError.extensions.validation, `input.${name}`);

    if (validationError) return validationError[0];

    return null;
  }

  function gqlValidationErrors(name: string, graphQLErrors: GraphQLError[]): Array<string> {
    const errors: string[] = [];
    graphQLErrors?.forEach((error: GraphQLError) => {
      const message = getGraphQLError(error, name);
      if (message) errors.push($t(`errors.${message}`));
    });
    return errors;
  }

  return { validate };
}
