import dayjs from "dayjs";

import "dayjs/locale/it";
import "dayjs/locale/en";

import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useI18n } from "vue-i18n";
dayjs.extend(isSameOrBefore);

export function useDayJS() {
  const { locale } = useI18n();
  dayjs.locale(locale.value);
  return { dayjs };
}
