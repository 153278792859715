import { createI18n } from "vue-i18n";
import _ from "lodash";

import { en as v_en, it as v_it } from "vuetify/locale";

import it from "../locales/it.json";
import en from "../locales/en.json";
import override_it from "../locales/override_it.json";
import override_en from "../locales/override_en.json";

const locale = localStorage.getItem("locale") || "it";

const it_messages = { $vuetify: v_it, ..._.merge(it, override_it) };

const en_messages = { $vuetify: v_en, ..._.merge(en, override_en) };

type ItMessageSchema = typeof it_messages;

export const i18n = createI18n<[ItMessageSchema], "it" | "en">({
  legacy: false,
  fallbackLocale: "it",
  locale,
  messages: {
    it: it_messages,
    en: en_messages,
  },
});
