<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn color="white" variant="outlined" v-bind="props">
        <span>Lang:</span>
        <span class="ml-2">{{ $i18n.locale.toUpperCase() }}</span>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list density="compact" class="py-0">
      <v-list-item
        @click="setLocale(item.locale)"
        link
        v-for="(item, index) in languages"
        :key="index"
      >
        <v-list-item-title>
          <span class="ml-2">{{ item.locale.toUpperCase() }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
// import { computed } from "vue";
import { useI18n } from "vue-i18n";

const languages = [
  { iso: "IT", locale: "it" },
  { iso: "GB", locale: "en" },
];

const { locale } = useI18n();

// const currentISO = computed(() => languages.find((language) => language.locale == locale.value)?.iso);

function setLocale(_locale: string) {
  locale.value = _locale;
  localStorage.setItem("locale", _locale);
}
</script>
