import dayjs from "dayjs";

import "dayjs/locale/it";
dayjs.locale("it");

import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

export default {
  install: (app: any) => {
    // inject a globally available $translate() method
    app.config.globalProperties.$dayjs = dayjs;
    app.provide("dayjs", dayjs);
  },
};
