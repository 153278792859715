import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/communications",
    name: "Communications",
    component: () => import("./Index.vue"),
  },
];

export default routes;
